.homeContainer { width:auto; height:auto; top:0; display:flex; justify-content:center; align-items:center; }
.homeCard { position:relative; height:40vw; width:18vw; max-width:20%; margin:2vw; transition:1s ease-in-out; transform-style: preserve-3d;   }
.homeCard-face { top:0; left:0; width:100%; height:100%; position:absolute; backface-visibility:hidden;  }
.homeCard-face--back { transform:rotateY(0.5turn) }
.homeCardImg { height:40vw; max-width:100%; object-fit:cover; border:0.2vw solid var(--col1); border-radius:3vw; }
.homeCard:hover { transform:rotateY(0.5turn) }
.nameContainer { height:auto; width:auto; margin:3vw 0; justify-content:center; align-items:center; display:flex; }
.name::before { content:attr(data-text); position:absolute; top:0; left:0; width:0; height:100%; color:var(--col1); -webkit-text-stroke: 0.1vw var(--col1); border-right: 2px solid var(--col1); overflow:hidden; animation: animate 2s linear 0.5s 1 normal forwards, flashBar 1s infinite; }
.name { position:relative; width:70vw; font-size:13vw; font-family:'Raleway',sans-serif; color:var(--col0); -webkit-text-stroke:0.3vw var(--col1); text-transform:uppercase; }

@keyframes animate { /* Name */
	0% { width: 0; }
	80% { width: 80%; }
	100% { width: 100%; }
}

@keyframes flashBar { /* Name */
	0% { border-right: 2px solid var(--col1); }
	50% { border-right: 2px solid var(--col0); }
	100% { border-right: 2px solid var(--col1); }
}

.background { width:100%; height:100%; position:fixed; background-size:cover; background-position:50% 50%; background-repeat:no-repeat; z-index:2; }

/* .header { height:100px; width: auto; color:var(--col1); } */

/* ABOUT PAGE */

.headerContainer { height:90vmin; width:98vmax; justify-content:right; align-items:center; display:flex; }
.headerContainer2 { height:auto; width:auto; align-items:center; justify-content:center; display:flex; padding:5vw; }
.headerImg { height:75vw; width:100vw; object-fit:cover; position:absolute; top:0; left:0; }
.header1 { height:auto; width:auto; font-size:10vw; font-weight:bold; color:var(--col1); z-index:10; padding:0; background-color:white; }
.header2 { height:auto; width:auto; font-size:10vw; font-weight:bold; color:var(--col1); z-index:10; padding:0; background-color:white; background-color:rgba(255, 255, 255, 0.5); border-radius:1vw; }

*::-webkit-scrollbar { width:0.6vw; height:1vh }
*::-webkit-scrollbar-track { background-color:transparent; }
*::-webkit-scrollbar-thumb { background-color:var(--col0); border-radius:10px; border:3px solid var(--col2) }

/* THE ANSH SECTION */

.wrapper { position:absolute; display:flex; overflow:hidden; }
.dynamic-txts { margin-left:0.5vw; height:4vw; }
.dynamic-txts li { list-style:none; color:var(--col1); font-size:2vw; position:relative; top:0; animation:slide 12s steps(4) infinite; width:97vw; }
.dynamic-txts li span { position:relative; margin-left:8vw; line-height:90px; }
.dynamic-txts li span::after { content:""; position:absolute; left:0; height:100%; margin-top:1.4vw; width:100%; background:var(--col0); border-left:2px solid var(--col6); animation:typing 3s steps(100) infinite; } 
@keyframes slide {
    100% { top:-360px; }
}
@keyframes typing {
    40%, 60% { left: calc(100% + 30px); }
    100% { left:0; }
}

.iframely-embed { position:fixed; top:0; left:0; width:100%; height:100%; z-index:50; background:white; }
#app { z-index:100; display:grid; grid-template-columns:1fr 2fr; align-items:center; padding-bottom:4vmin; height:93vh; width:100%; background:var(--col6); color:var(--col1); }
body { overflow-x:hidden; }
.title { padding-left:1em; grid-column:1/-1; grid-row:1; font-size:8vw; width:100%; z-index:2; }
.title-inner { display:inline-block; }
  
@keyframes text-clip {
    from { clip-path:polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%); }
    to { clip-path:polygon(0 0, 100% 0, 100% 100%, 0 100%); }
}
  
@keyframes outer-left {
    from { transform:translateX(50%); }
    to { transform:none; }
}
  
@keyframes inner-left {
    from { transform:translateX(-50%); }
    to { transform:none; }
}
.cafe, .mozart { animation:outer-left 1s 1s cubic-bezier(0.5, 0, 0.1, 1) both; }
.title-inner { display:inline-block; animation:inner-left 1s 1s ease both; }
.cafe-inner { background-color:var(--col0); padding:0 5vw; display:inline-block; font-weight:bold; animation:inner-left 1s 1s ease both, text-clip 1s 0s cubic-bezier(0.5, 0, 0.1, 1) both; }
.mozart-inner { background-color:var(--col0); padding:0 5vw; animation:text-clip 1s 0s cubic-bezier(0.5, 0, 0.1, 1) both; font-weight:bold; }
.title { animation:outer-left 1s 1s ease both; }
.mozart { display:inline-block; }
.image { grid-row:1; grid-column:2; margin-left:-2rem; opacity:1; animation:image-in 1s cubic-bezier(0.5, 0, 0.1, 1) 2s backwards; }
@keyframes image-in {
    from { clip-path:polygon(0 0, 100% 0, 100% 0, 0 0); }
    to { clip-path:polygon(0 0, 100% 0, 100% 100%, 0 100%); }
}
img { display:block; width:100%; height:auto; }

.gridContainer { justify-content:center; align-items:center; height:auto; width:100%; margin:5vw auto; display:grid; grid-template-columns:repeat(2, 1fr); grid-gap:8vw; }
.gridTileR { height:23vw; width:100%; background-color:rgba(255, 229, 236, 0.2); display:flex; align-items:center; justify-content:center; padding-left:25vw; padding-right:1.5vw; border-bottom:solid 0.2vw var(--col2); border-right:solid 0.2vw var(--col2); border-top:solid 0.2vw var(--col2); }
.containerR__image { position:relative; display:flex; flex-direction:column; align-items:start; justify-content:center; width:18vw; height:18vw; border-radius:50%; background-size:cover; transition: ease-in-out .3s; z-index:2; }
.containerR__image:before { content:''; position:absolute; width:100%; height:100%; top:0; bottom:0; left:0; margin:auto; background:inherit; background-position:bottom; filter:blur(40px) saturate(0%); transform:scaleX(0.4); transition:ease-in-out .4s; border-radius:120px; transform-origin:right; opacity:0; z-index:-10; } 
.containerR__info { background-color:rgba(255, 229, 236, 0.5); padding:0 1vw; position:relative; line-height:1.8; transition:ease-in-out .3s; opacity:0; justify-self:right; text-align:left; } 
.containerR__location { transition-delay:0.15s; }
.containerR__image:hover { border-radius:0; width:30vw; height:20vw; box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(9, 55, 53, 0.08), 0px 16px 24px rgba(9, 55, 53, 0.1), 0px 24px 32px rgba(9, 55, 53, 0.14); }
.containerR__image:hover:before { width:100%; height:100%; opacity:0.18; filter:blur(10px) saturate(100%); transform:scale(2) translate3d(-18%, 0px, 0px); }
.containerR__image:hover > .containerR__info { top:0; left:0; opacity:1; }
.gridTileL { height:23vw; width:100%; background-color:rgba(255, 229, 236, 0.2); display:flex; align-items:center; justify-content:center; padding-left:25vw; padding-right:1.5vw; border-bottom:solid 0.2vw var(--col2); border-left:solid 0.2vw var(--col2); border-top:solid 0.2vw var(--col2); }
.containerL__image { position:relative; display:flex; flex-direction:column; align-items:end; justify-content:center; width:18vw; height:18vw; border-radius:50%; background-size:cover; transition: ease-in-out .3s; z-index:2; }
.containerL__image:before { content:''; position:absolute; width:100%; height:100%; top:0; bottom:0; right:0; margin:auto; background:inherit; background-position:bottom; filter:blur(40px) saturate(0%); transform:scaleX(0.2); transition:ease-in-out .4s; border-radius:120px; transform-origin:left; opacity:0; z-index:-10; } 
.containerL__info { background-color:rgba(255, 229, 236, 0.5); padding:0 1vw; position:relative; line-height:1.8; transition:ease-in-out .3s; opacity:0; right:0; text-align:right; } 
.containerL__location { transition-delay:0.15s; }
.containerL__image:hover { border-radius:0; width:30vw; height:20vw; box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(9, 55, 53, 0.08), 0px 16px 24px rgba(9, 55, 53, 0.1), 0px 24px 32px rgba(9, 55, 53, 0.14); }
.containerL__image:hover:before { width:100%; height:100%; opacity:0.18; filter:blur(10px) saturate(100%); transform:scale(2) translate3d(18%, 0px, 0px); }
.containerL__image:hover > .containerL__info { opacity:1; right:0; }

.backgroundDiv { background-color:var(--col1); height:100vh; width:100% }
body{ position:relative; }

.sideImageContainer { height:calc(100% - 8vh); width:18vw; background-color:transparent; position:absolute; display:grid; grid-template-rows:repeat(auto, 18vw); overflow:hidden; z-index:-1000; }
.sideImage { height:18vw; width:18vw; background-size:cover; }

.hobbiesContainer { position:relative; background-color:rgba(44, 43, 48, 0.8); height:auto; width:80vw; margin:auto; border-radius:1vw; border-color:var(--col2); border-width:0.2vw; display:flex; justify-content:center; padding:3vw; }
.gridContainer2 { height:auto; width:auto; justify-content:center; }
.hobbiesGrid { width:79vw; display:grid; grid-template-columns:repeat(2, 1fr); justify-content:center; grid-gap:5vw; }
.hobbiesGrid2 { display:grid; grid-template-columns:repeat(4, 1fr); height:5vw; width:auto; margin-top:2vw; }
.hobbiesButton { transition:0.5s; color:var(--col1); background-color:var(--col0); font-size:2vw; text-align:center; height:fit-content; width:100%; border-radius:0.5vw; margin:0.8vw auto 1.2vw auto; padding:0.5vw 0; border-width:0.15vw; border-color:var(--col1) } 
.hobbiesButton:hover { background-color:var(--col1); color:var(--col) }
.hobbiesTitle { width:auto; color:var(--col1); text-align:center; border-bottom:0.2vw solid white; padding-bottom:0vw; justify-content:center; font-size:3.5vw; font-weight:1; }
.hobbiesContent { margin-top:2vw; text-align:justify; text-justify:inter-word; content:attr(data-text); font-size:1.2vw; color:var(--col1); font-weight:1; }
.icons2 { margin:0 auto; color:var(--col1) }
.icons2:hover { cursor:pointer; color:var(--col0); }
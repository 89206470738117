img { object-fit:cover; }
.logo { height: 4vw; width:auto; margin:0; }

.card { height:auto; margin:2vw 14vw; display:grid; grid-template-columns:repeat(2, auto); background-color:var(--col0); border-radius:1vw; border:0.2vw solid var(--col1); transition: 0.2s; }
.card:hover { box-shadow: 0 0 30px 5px var(--col1) }
.cardContainer { margin: 2vw 2vw 2vw 0; }
.cardHeader { font-size:3vw; color:var(--col5); border-bottom:0.2vw solid var(--col1); text-transform:uppercase; }
.cardBody { grid-column-start:1; grid-row-start:2; position:relative; font-size:1.5vh; color:var(--col5); padding:1vw 1vw 1vw 0; font-weight:100; }
.cardDate { margin-top:1.2vw; float:right; color:var(--col1); letter-spacing:0.1vw; align-items:center; justify-content:center; font-size:2.5vh; }
.cardImage { width:26vw; margin:2vw; height:15vw; overflow:hidden; }
.cardButton { align-items:center; justify-content:center; letter-spacing:0.1vw; text-transform:uppercase; padding:0.5vw 0.7vw; height:auto; width:auto; display:inline-block; text-decoration:none; font-size:0.8vw; color:var(--col1); border:0.1vw solid var(--col1); border-radius:1.5vmin; background-size:100vw 100vw; transition:0.5s; margin:1vw 2vw 1vw 0; }
.cardButton:hover  { color:var(--col0); background:var(--col1); background-position:50vw 0; }

.popup { z-index:2000; top:0; position:fixed; width:100%; height:100%; background-color: rgba(0, 0, 0, 0.5); display:flex; justify-content: center; }  
.popup-inner { position:relative; top:5vw; padding:32px; width:50%; height:auto; background-color:#FFF; border-radius:1.5vmin; border:0.15vw solid black; background:var(--col0);  }
.popup-inner .close-btn { right:16px; }
.close-btn { align-items:center; justify-content:center; letter-spacing:0.1vw; text-transform:uppercase; text-decoration:none; padding:0.2vw 0.3vw; height:auto; width:auto; font-size:0.5vw; color:var(--col1); border:0.1vw solid var(--col1);border-radius:1.5vmin; background-size:100vw 100vw; transition:0.5s; }
.close-btn:hover { color:var(--col0); background:var(--col1); background-position:50vw 0; }

.container1 { justify-content:center; display:flex; align-items:center; margin: 10px 0; }
.header { font-size:2vw; font-weight:bold; color:var(--col0) }
.icons{ color:var(--col0); font-size:3rem; transition:0.5s;  }
.icons:hover { color:var(--col6); cursor:pointer; }
#discord:hover ~ .discUser { color:var(--col6); }
.discUser { margin-top:1.1vw; font-size:1.5rem; color:transparent; height:auto; width:auto; position:absolute; right:6%; transition:0.5s; }
#email:hover ~ .email { color:var(--col6); }
.email { margin-top:1.1vw; font-size:1.5rem; color:transparent; height:auto; width:auto; position:absolute; right:4%; transition:0.5s; }

.timelineContainer { justify-content:center; display:flex; align-items:center; padding:0 19vw; margin-bottom:50px }
.main-timeline { position:relative }
.main-timeline:before { content:""; display:block; width:2px; height:100%; background:#c6c6c6; margin:0 auto; position:absolute; top:0; left:0; right:0 }
.main-timeline .timeline { margin-bottom:40px; position:relative }
.main-timeline .timeline:after { content:""; display:block; clear:both }
.main-timeline .icon { width:18px; height:18px; line-height:18px; margin:auto; position:absolute; top:0; left:0; bottom:0; right:0 }
.main-timeline .icon:before, .main-timeline .icon:after { content:""; width:100%; height:100%; border-radius:50%; position:absolute; top:0; left:0; transition: all 0.33s ease-out 0s }
.main-timeline .icon:before { background:var(--col1); border:2px solid #232323; left:-3px }
.main-timeline .icon:after { border:2px solid #c6c6c6; left:3px }
.main-timeline .timeline:hover .icon:before { left:3px }
.main-timeline .timeline:hover .icon:after { left:-3px }
.main-timeline .date-content { width:50%; float:left; margin-top:22px; position:relative }
.main-timeline .date-content:before { content:""; width:36.5%; height:2px; background:#c6c6c6; margin:auto 0; position:absolute; top:0; right:10px; bottom:0 }
.main-timeline .date-outer { width:125px; height:125px; font-size:16px; text-align:center; margin:auto; z-index:1 }
.main-timeline .date-outer:before, .main-timeline .date-outer:after { content:""; width:125px; height:125px; margin:0 auto; border-radius:50%; position:absolute; top:0; left:0; right:0; transition: all 0.33s ease-out 0s }
.main-timeline .date-outer:before { background:var(--col1); border:2px solid #232323; left:-6px }
.main-timeline .date-outer:after { border:2px solid #c6c6c6; left:6px }
.main-timeline .timeline:hover .date-outer:before { left:6px }
.main-timeline .timeline:hover .date-outer:after { left:-6px }
.main-timeline .date { width:100%; margin:auto; position:absolute; top:27%; left:0 }
.main-timeline .month { font-size:18px; font-weight:700 }
.main-timeline .year { display:block; font-size:30px; font-weight:700; color:#232323; line-height:36px }
.main-timeline .timeline-content { width:50%; padding: 20px 0 20px 50px; float:right }
.main-timeline .timelineTitle { color:var(--col1); font-size:19px; font-weight:700; line-height:24px; margin: 0 0 15px 0 }
.main-timeline .description { margin-bottom:0; color:var(--col1) }
.main-timeline .timeline:nth-child(2n) .date-content { float:right }
.main-timeline .timeline:nth-child(2n) .date-content:before { left:10px }
.main-timeline .timeline:nth-child(2n) .timeline-content { padding: 20px 50px 20px 0; text-align:right }

@media only screen and (max-width: 991px) {
    .main-timeline .date-content { margin-top: 35px }
    .main-timeline .date-content:before { width: 22.5% }
    .main-timeline .timeline-content { padding: 10px 0 10px 30px }
    .main-timeline .timelineTitle { font-size:17px; }
    .main-timeline .timeline:nth-child(2n) .timeline-content { padding: 10px 30px 10px 0 }
}

@media only screen and (max-width: 767px) {
    .main-timeline:before { margin:0; left:7px }
    .main-timeline .timeline { margin-bottom:20px }
    .main-timeline .timeline:last-child { margin-bottom:0 }
    .main-timeline .icon { margin:auto 0 }
    .main-timeline .date-content { width:95%; float:right; margin-top:0 }
    .main-timeline .date-content:before { display:none }
    .main-timeline .date-outer { width:110px; height:110px }
    .main-timeline .date-outer:before, .main-timeline .date-outer:after { width:110px; height:110px }
    .main-timeline .date { top:30% }
    .main-timeline .year { font-size:24px }
    .main-timeline .timeline-content, .main-timeline .timeline:nth-child(2n) .timeline-content { width:95%; text-align:center; padding:10px 0 }
    .main-timeline .timelineTitle { margin-bottom:10px }
}

.flipCard { height:40vw; width:18vw; border:solid white; }
.flipCardImage { height:40vw; width:18vw; border-radius:3vw; border:solid var(--col1); margin:0 2vw; }
.flipCardImage:hover { cursor:pointer; }

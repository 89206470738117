:root {
  --col0: #2C2B30;
  --col1: #FFE5Ec; 
  --col2: #F8B7CD; 
  --col3: #C8E7F5; 
  --col4: #909294; 
  --col5: #EBECF0;
  --col6: #a3999c;
  --col7: #7a797d;
  --col8: #fff7f9; 
  padding-top:8vh;
}

.app { width:100%; height:100%; }
* { box-sizing:border-box; margin:0; padding:0; font-family:'Lato',sans-serif }
body { background-color:var(--col0); min-height:100vh;}